body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f9fe !important;
  font-family: 'Source Sans Pro', sans-serif !important;
  width: 100vw !important;
  height: 100vh !important;
  scroll-behavior: smooth;
  overflow: hidden;
}

/*************** Default style overwritten HERE ***************/

/* React Table Style Updated */
.ReactTable,
.ReactTable .rt-table {
  border-radius: 3px !important;
}

.ReactTable.-striped .rt-tr.-odd {
  background: #ffffff !important;
}

.ReactTable.-striped .rt-tr.-even {
  background: #f7f7f7 !important;
}

.ReactTable .rt-tbody .rt-td {
  display: flex;
  border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.ReactTable .rt-thead .rt-th {
  border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
}

/* Remove scrollbar space */
/* Optional: just make scrollbar invisible */
/* .rt-table::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background: transparent;
} */
#subTableDiv > .ReactTable > .rt-table > .-header {
  background-color: #beeaff !important;
}

#subTableDivSI > .ReactTable > .rt-table > .-header {
  position: initial !important;
}

#subTableDivBD > .ReactTable > .rt-table > .-header {
  position: initial !important;
}

.ReactTable .rt-thead.-header {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15);
  background-color: #e5e6e7;
  padding: 5px 5px;
}

.vvdAllocation .rt-thead.-header {
  padding: 0 !important;
}

.ReactTable.-striped .rt-tr.-even {
  background-color: #e8e8e8;
}

.-pagination .-previous,
.-pagination .-next {
  flex-grow: 0.5 !important;
}

.-next .-btn,
.-previous .-btn {
  background-color: #426096 !important;
  color: #fff !important;
}

/* AirBNB Date Range Picker Style Updated */
.DateRangePicker__block {
  width: calc(100% - 39px);
  border: none;
  border-radius: 3px !important;
}

.DateRangePickerInput {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px !important;
}

.DateInput {
  border-radius: 3px !important;
}

.DateInput_input {
  padding: 5px 10px !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  border-radius: 3px !important;
}

.DateRangePicker_picker {
  z-index: 6 !important;
}

.DateInput_input__focused {
  border-bottom: none !important;
}

.DateInput_input__disabled {
  background-color: #e9ecef !important;
}

.DateRangePickerInput__disabled {
  background-color: #e9ecef !important;
}

/* Used for ContainerList.js for table & ContainerTraking.js */
.table-striped > tbody > tr:nth-child(2n + 1) > td,
.table-striped > tbody > tr:nth-child(2n + 1) > th {
  background-color: #ffffff;
}

.table td,
.table th {
  border: none;
  /* vertical-align: middle !important; */
}

td,
th {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  color: #525a67;
}

.rt-noData {
  display: none !important;
}

/* Container DashBoard */
.input-group-text {
  background-color: #fff;
  border: none;
}

.custom-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 135px);
  margin-top: 80px;
}

.form-control::placeholder {
  color: #d6d7d8;
  /* color: #6c757d; */
  opacity: 1;
}

/*Container Tracking */
.modal-dialog {
  max-width: 90% !important;
  margin: auto;
  /* margin-top: 80px; */
}

.dialog-update-size {
    max-width: 60% !important;
    min-width: 450px;
    overflow-x: auto;    
}

/*Activity Loader modal background*/
#activityModal .modal-content,
#alertModal .modal-content,
#termsConditions .modal-content {
  background-color: transparent;
  border: none;
  justify-content: center;
  align-items: center;
}

/*Container Search radio button color change for theme*/
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #69a5f8;
  background-color: #69a5f8;
}

/*Header bar dropdown icon removed and added custom*/
#header-nav-dropdown:after {
  display: none;
}

/* Remove form check on validation and valid data on Registration*/
.form-control.is-valid,
.was-validated .form-control:valid {
  background-image: none !important;
  border-color: #ced4da;
}

/* Remove icons in number input*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

/* Dropdown for Booking Request multi-select */
.dropdown-content {
  z-index: 4 !important;
}

/* Phone Input control style overwrite*/
.react-tel-input .form-control {
  width: 100% !important;
  height: 38px !important;
}

#vesselSchedule {
  max-width: 100% !important;
}

.rt-table::-webkit-scrollbar {
  height: 8px;
}

.rt-table::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}

.rt-table::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #7c7c7d;
}

.rthfc.-striped .rt-tr.-odd.-inactive .rt-td {
  background-color: #ffbaba;
}

.rthfc.-striped .rt-tr.-even.-inactive .rt-td {
  background-color: #ffbaba;
}

.rthfc.-striped .rt-tr.-odd.-future .rt-td {
  background-color: #feefb3;
}

.rthfc.-striped .rt-tr.-even.-future .rt-td {
  background-color: #feefb3;
}
