.mainContainer {
  flex: 1;
  display: flex;
  height: 100vh;
  width: 100vw;
  top: 0px;
  bottom: 0px;
  position: absolute;
  padding: 80px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f3f9fe;
}
.subComp {
  display: flex;
  width: 100%;
  height: 65%;
  justify-content: center;
  align-items: center;
}
.card {
  /* width: 500px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.img404 {
  width: 300px;
}
.title {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 58px;
  color: #3a5f98;
  text-align: center;
}
.text {
  font-size: 18px;
  color: #333;
  text-align: center;
  /* margin-left: 400px;
  margin-right: 400px; */
  padding-top: 10px;
  padding-bottom: 25px;
}
.link {
  font-size: 20px;
  color: #000;
  text-align: center;
}
.footer {
  position: absolute;
  bottom: 0px;
}

@media only screen and (max-width: 576px) {
  .card {
    width: 90%;
  }
  .img404 {
    width: 160px;
  }
  .title {
    font-size: 35px;
  }
  .text {
    font-size: 19px;
  }
  .link {
    font-size: 16px;
  }
}
