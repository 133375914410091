.rootContainer {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
@media only screen and (max-width: 576px) {
  .rootContainer {
    overflow: hidden;
  }
}
