.footer {
  background-color: #222222;
  color: #ffffff;
  width: 100%;
}
.footerText {
  color: #ffffff;
}
.footerText:hover {
  color: #68b1ff;
}
@media only screen and (max-width: 576px) {
  /* .footer-copyright text-center py-3  */
  .footer {
    padding: 0px 15px;
  }
}
