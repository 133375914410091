.modalContainer {
  overflow: hidden !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.alertCard {
  background-color: #fff;
  width: 550px;
  border-radius: 3px;
  border: 1px solid #f2f2f2;
}
.btnCloseDiv {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 10px 10px 0px 0px;
}
.closeIcon {
  color: #a4a4a4;
  font-size: 25px;
  cursor: pointer;
}
.imgIconDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}
.imgIcon {
  height: 60px;
  width: auto;
}
.txtTitleDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.txtTitle {
  color: #000;
  font-size: 25px;
  font-weight: 500;
  font-family: 'Source Sans Pro', sans-serif;
}
.txtMsgDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}
.txtMsg {
  width: 95%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000;
  font-size: 17px;
  font-weight: 300;
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
  white-space: pre-line;
  border: '1px solid black';
}
.btnButtonDiv {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 35px;
}
.btnButton {
  width: 150px;
  align-self: center;
  font-size: 20px;
  background-color: #69a5f8;
  border-color: #69a5f8;
  margin: 0px !important;
}
@media only screen and (max-width: 550px) {
  .alertCard {
    width: 300px;
  }
  .btnButton {
    width: 100px;
  }
}
