.headerBar {
  background-color: #ffffff;
  /* boxS-shadow: 0px 4px 10px #aaaaaa; */
  height: 80px;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
  width: 100%;
  /* position: static !important; */
}

.logo {
  height: 55px;
}

.navLink {
  padding: 0px;
}

.titleText {
  font-size: 18px;
  color: #33609a;
  padding: 0px;
  margin: 0px 10px;
  font-family: 'Source Sans Pro', sans-serif !important;
}

.AssumeTitleText {
  font-size: 17px;
  padding: 0px;
  margin: 0px 20px;
  color: white;
  background-color: #2f5f9d;
  font-family: 'Source Sans Pro', sans-serif !important;
}

.titleText_Bold {
  font-size: 20px;
  font-weight: 700;
  color: #33609a;
  padding: 0px;
  margin: 0px 10px;
  font-family: 'Source Sans Pro', sans-serif !important;
  border-bottom: 2px solid;
}

.navbar-brand {
  margin: 0px;
}

.collasibleNavDropdown,
.collasibleNavDropdown > a {
  /* background-color: #f1f1f1 !important; */
  color: #777777 !important;
  border-color: #f1f1f1 !important;
  border-radius: 3px;
}

.imgFlag {
  margin: 0px 10px;
  cursor: pointer;
}

.modal-90w {
  width: 30%;
}

.modal-dialog {
  max-width: 90% !important;
  margin: auto;
  margin-top: 80px;
}

.button {
  align-self: center;
  font-size: 20px;
  margin-bottom: 10px;
  background-color: #69a5f8;
  border-color: #69a5f8;
}

.button_yes {
  align-self: center;
  font-size: 20px;
  background-color: #69a5f8;
  border-color: #69a5f8;
}

.button_no {
  align-self: center;
  font-size: 20px;
  background-color: #999999;
  border-color: #999999;
}

.button_no:hover {
  color: #fff;
  background-color: #666666;
  border-color: #666666;
}

.infobar {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  background-color: #f1f1f1;
  margin-top: 80px;
}

.version {
  margin-left: 24px;
  font-size: 12px;
  color: #888;
}

.txtInfoNote {
  font-size: 16px;
  background-color: lightskyblue;
  font-weight: 600;
  padding: 3px;
}

.collasibleNavDropdownSpan {
  display: flex;
  justify-content: center;
  align-items: center;
}

.collasibleNavDropdownDiv {
  margin-left: 10px;
}

.assumeUserModal {
  margin-left: 35%;
  margin-top: 5%;
  margin-right: auto;
  width: 35%;
}

@media only screen and (max-width: 990px) {
  .collasibleNavDropdownSpan {
    justify-content: left;
  }

  .collasibleNavDropdownDiv {
    margin-left: 0px;
  }

  .collasibleNavDropdown > div {
    left: 0%;
    right: auto;
  }

  .titleText_Bold {
    border: none;
  }
}

@media only screen and (max-width: 576px) {
  .headerBar {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .infobar {
    padding-left: 15px;
    padding-right: 15px;
  }

  .assumeUserModal {
    margin-left: 10%;
    width: 80%;
  }
}
